<template>
  <div>
    <section-banner v-for="(section, index) in sections" :key="index" :model="section"></section-banner>
  </div>
</template>

<script>
import SectionBanner from "@/components/Frontend/SectionBanner";

export default {
  name: 'Wealth',
  components: {
    SectionBanner
  },
  data() {
    return {
      sections: [
        {
          Title: "Wealth",
          Subtitle: "Put your money where it matters",
          Description: "NLA Wealth is a unique wealth management business creating customised, holistic solutions to suit your business, your family, and your needs.",
          img: require("@/assets/img/Eric.jpg")
        },
        {
          TextColumn: true,
          TextColumn1: `<p>Our focus is on sourcing unique opportunities to generate new wealth, while preserving existing wealth. We are positioned to leverage our extensive multidisciplinary expertise and experience : encompassing business strategy and advisory services, estate planning, product design, as well as finance, legal, regulatory, compliance and wealth management. We are experts partnering with experts to offer you a superlative wealth solution.</p><p>If you’ve received a large settlement - from a divorce, property sale, or inheritance - it’s often bewildering to know where best to put it. With our existing insight into you and your business, we can help give the most relevant advice about what to do with your windfall, and the safest place to put it.</p>`,
            TextColumn2: false 
        },
        {
          Profiles: true,
          Name: "Talk to Us",
            Bios: [
            {
              Title: "Eric Meyer",
              Subtitle: "Director",
              Linkedin: "linkedin.com/in/eric-meyer",
              Email: "eric@NLAwealth.com",
              Tel: "+27 10 466 1777",
              img: require("@/assets/img/Eric-bio.jpg"),
              left: true,
            },
            {
              Title: "Louis Pfieffer",
              Subtitle: "Director",
              Linkedin: "linkedin.com/in/louis-pfeiffer",
              Email: "louis@NLAwealth.com",
              Tel: "+27 10 466 1777",
              img: require("@/assets/img/Louis-bio.jpg"),
              left: false,
              last: true
            }
          ],
        },
      ]
    }
  },
}
</script>

<style lang="scss" scoped>

</style>